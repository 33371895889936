@font-face {
  font-family: 'Avenir';     
  src: url('fonts/Archia/AvenirNext_Variable.ttf') format("ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';      
  src: url('fonts/Archia/AvenirNext_Variable.ttf') format("ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';     
  src: url('fonts/Archia/AvenirNext_Variable.ttf') format("ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';      
  src: url('fonts/Archia/AvenirNext_Variable.ttf') format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';      
  src: url('fonts/Archia/AvenirNext_Variable.ttf') format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';    
  src: url('fonts/Archia/AvenirNext_Variable.ttf') format("ttf");
  font-weight: 900;
  font-style: normal;
}

/* -- */

$blue: #007bff;
$blue-light: #007bff10;
$blue-hover: #007bff30;
$blue-light-opaque: #eff7ff;
$blue-mid: #007bff99;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$cherry: #ff073a;
$cherry-mid: #ff073a99;
$cherry-light: #ff073a20;
$cherry-hover: #ff073a30;
$cherry-light-opaque: #ffe0e6;
$red: #dc3545;
$red-light: #dc354520;
$red-mid: #dc354599;
$orange: #fd7e14;
$orange-mid: #fd7e1499;
$orange-light: #fd7e1420;
$orange-hover: #fd7e1430;
$orange-light-opaque: #ffefe2;
$yellow: #ffc107;
$yellow-light: #ffc10720;
$yellow-hover: #ffc10730;
$yellow-mid: #ffc10799;
$yellow-light-opaque: #fff7e0;
$green: #28a745;
$green-light: #28a74520;
$green-hover: #28a74530;
$green-mid: #28a74599;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-light: #6c757d10;
$gray-hover: #6c757d20;
$gray-mid: #6c757d99;
$gray-dark: #343a40;
$gray-opaque: #343a4020;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$purple: #201aa2dd;
$purple-light: #201aa220;
$purple-hover: #201aa230;
$purple-mid: #201aa299;
$purple-light-opaque: #e3e2f3;

@mixin navbarReveal($color) {
  $animation-name: unique-id() !global;

  animation-name: $animation-name;
  animation-duration: 0.750s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;

  @keyframes #{$animation-name} {
    0% {
      background: transparent;
    }
    100% {
      background: $color;;
    }
  }
}

@mixin levelColor($color) {
  .is-#{$color} {
    h1 {color: $color;}
    h4, h5 {color: #{$color}-mid;}
  }
}

body {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

p {
    font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: #343a40;
    text-rendering: optimizeLegibility;
    line-height: 1.6;
}

h1, {
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 24px !important;
  font-weight: 600;
  text-transform: none;
}

h2 {
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 18px !important;
  font-weight: 500;
  text-transform: none;
}

h3 {
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 17px !important;
  font-weight: 500;
  text-transform: none; 
}

h4 {
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px !important;
  font-weight: 500;
  text-transform: none; 
}

h5 {
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 14px !important;
  font-weight: 500;
  text-transform: none; 
}

h6 {
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 13px !important;
  font-weight: 500;
  text-transform: none; 
}

.responsive {width:100%;height: auto;}

.Home {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: 10rem;
  margin-right: 10rem;
  .home-left, .home-right  {
    display: flex;
    flex-direction: column;
    width: 33rem;
  }
}

.Banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 14px;
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 15px;
  font-weight: 500;
  color: $purple;
  background: $purple-light;
  .snippet {
    align-self: center;
    width: calc(100% - 55rem);
    word-wrap: break-word;
    padding: 1.1rem;
    line-height: 1.3;
    text-align: center;
  }
}


.Navbar {
  display: flex;
  flex-direction: column;
  height: 3rem;
  justify-content: space-around;
  text-transform: uppercase;
  font-weight: 600;
  @include navbarReveal($gray-light);
  .navbar-left {
    margin-left: 2rem;
    margin-top: 1rem;
    flex: 1;
    align-self: center;
    a {
      display: inline-block;
      font-size: 14px;
      font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      text-decoration: uppercase;
      margin-right: 2rem;
      color: $gray-mid;
      span {
        display: inline-block;
        &:hover {
          color: $gray;
        }
      }
    }
  }
  .focused {
    color: $gray;
  }
  img {
    width: 3rem;
    height: 3rem;
    display: none;
    align-self: center;
    margin-left: 5rem;
    cursor: pointer;
  }
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30rem;
  align-self: center;
  justify-content: space-between;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
  h1, h6 {
    margin: 0;
  }
  h1 {
    color: $gray-dark;
    margin-bottom: 1rem;
  }
  .header-mid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > * {
      align-self: center;
    }
  }
}

.last-update {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: right;
  margin-bottom: auto;
  margin-top: 0.25rem;
  h6 {
    color: $green-mid;
    font-weight: 600;
  }
  h3 {
    color: $green;
    font-weight: 600;
  }
  h3, h6 {
    margin: 0;
  }
}

a.button {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border: none;
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  text-transform: uppercase;
  background: $blue-light;
  color: $blue-mid;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px; 
  transition: background 0.2s ease-in-out;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  outline: none;
  font-size: 0.75rem;
  text-decoration: none;
  svg {
    margin-right: 0.5rem;
    width: 20px;
    stroke-width: 2px;
  }
  &.is-purple {
    color: $purple-mid;
    background: $purple-light;
    &:hover {
      background: $purple-hover;
    }
  }
    &.is-green {
    color: $green-mid;
    background: $green-light;
    &:hover {
      background: $green-hover;
    }
  }
  &:hover {
  background: $blue-hover;
  }
  & > * {
    align-self: center;
  }
}

.telegram {
  width: 15.5rem;
  background: $gray-light !important;
  color: #0088cc !important;
  padding-left: 0.65rem;
  &:hover {
    background: $gray-hover !important;
  }
}

.excel {
  color: #33A667;
  background: #33A66730;
  &:hover {
    background: #33A66750;
  }
}

.Level {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 30rem;
  .level-item {
    display: flex;
    flex-direction: column;
    & > * {
      align-self: center;
    }
    &:first-child {
      margin-left: 0.5rem;
    } 
    &:last-child {
      margin-right: 0.5rem;
    }
  }
  h1, h5 {
    margin-bottom: 0;
  }
  h1 {
    font-weight: 600;
  }
  h4 {
    margin-bottom: -0.5rem;
  }
  .is-cherry {
    h1 {color: $cherry;}
    h4, h5 {color: $cherry-mid;}
  }
  .is-blue {
    h1 {color: $blue;}
    h4, h5 {color: $blue-mid;}
  }
  .is-green {
    h1 {color: $green;}
    h4, h5 {color: $green-mid;}
  }
  .is-gray {
    h1 {color: $gray;}
    h4, h5 {color: $gray-mid;}
  }
}

abbr {
  text-decoration: none;
  &.is-cherry {
    color: $cherry;
  }
  &.is-blue {
    color: $blue;
  }
  &.is-green {
    color: $green;
  }
  &.is-gray {
    color: $gray;
  }
}

table {
  position: relative;
  width: 30rem;
  align-self: center;
  font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 2rem;
  thead {
    background: $gray-light;
    color: $gray-dark;
    text-align: left;
    font-size: 0.75rem;
    th {
      padding: 0.5rem;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.1s ease-in-out;
      &:hover {
        background: $gray-hover;
      }
      .heading-content {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-direction: row;
        height: 0.9rem;
        svg {
          width: 10px;
          margin: 0;
          right: 0;
          transform: rotate(135deg);
          stroke-width: 4px;
          align-self: center;
          margin-top: -0.35rem;
          margin-left: 0.25rem;
          color: $gray-mid;
        }
      }
    }
  }
  tbody {
    color: $gray;
    tr {
      transition: background 0.1s ease-in-out;
      &:hover {
        background: $gray-hover;
      }
    }
    tr:nth-child(odd){
      background-color: $light;
      &:hover {
        background: $gray-hover;
      }
    }
    td {
      padding: 0.25rem;
      font-size: 0.9rem;
      border-radius: 5px;
      text-align: right;
      .deltas {
        margin-right: 0.25rem;
        font-size: 11px !important;
        svg {
          width: 9px;
          height: 9px;
          stroke-width: 3;
          vertical-align: -0.25px;
        }
      }
    }
    td:first-child {
      text-align: left;
    }
  }
  .affected-count {
    position: absolute;
    margin: 0;
    top: -1rem;
    color: $gray-mid;
    right: 0;
  }
  .is-total {
    td {
      background: $gray-hover;
    }
  }
}

.ChoroplethMap {
  display: flex;
  position: relative;
  align-self: center;
  margin-top: 4rem;
  margin-bottom: -10rem;
  width: 30rem;
  .header {
    position: absolute;
    top: -5rem;
    color: $gray-dark;
  }
  h6.header {
    margin-top: 3rem;
    color: $gray;
    margin-left: 1px;
    width: 10rem;
  }
  .svg-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    svg {
      align-self: center ;
      text {
        text-transform: uppercase;
        text-align: right;
        font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-weight: 600;
        fill: $gray;
        font-size: 14px;
      }
    }
  }
  .map-stats {
    flex: 0.1;
    position: absolute;
    top: 1.25rem;
    left: 1px;
    h1, h5, h6 {
      margin: 0;
    }
    h4 {
      color: #e23028;
      width: 5rem;
    }
    h1 {
      font-weight: 600;
    }
    .stats {
      background: $cherry-light;
      color: $cherry;
      padding: 0.5rem;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      .stats-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        h6 {
          margin-top: auto;
          margin-bottom: 0.25rem;
        }
      }
      &.is-green {
        background: $green-light;
        h5, h6 {color: $green-mid;}
        h1 {color: $green;}
      }
      &.is-blue {
        background: $blue-light;
        h5, h6 {color: $blue-mid;}
        h1 {color: $blue;}
      }
      &.is-gray {
        background: $gray-light;
        h5, h6 {color: $gray-mid;}
        h1 {color: $gray;}
      }
    }
  }
}

.tabs {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: row;
  .tab {
    min-width: 5rem;
    background: $gray-light;
    padding-left: 1rem;
    padding-right: 1rem;
    color: $gray-mid;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin-right: 0.25rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: $gray-hover;
    }
    &.focused {
      color: $gray;
      background: $gray-opaque;
    }
  }
}

.TimeSeries-Parent {
  display: flex;
  flex-direction: column;
  width: 30rem;
  align-self: center;
}

.timeseries-header {
  h1 {
    color: $gray-dark;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  width: 30rem;
  align-self: center;
  margin-top: 1rem;
  .timeseries-mode {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 4.5rem;
    right: 0;
    z-index: 99;
    input {margin: 0; cursor: pointer;}
    label {
      font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-right: 0.5rem;
      color: $gray;
      z-index: 99;
    }
  }
}


.timeseries, .Minigraph {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  .stats {
    padding: 0.25rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 5rem;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    h2, h5, h6 {
      margin: 0;
      color: $cherry-mid;
    }
    h2, h6 {
      color: $cherry;
    }
    .stats-bottom {
      display: flex;
      flex-direction: row;
      h6 {
        margin-bottom: 0.1rem;
        margin-left: 0.25rem;
      }
      & > * {
        margin-top: auto;
      }
    }
    &.is-green {
      h5 {color: $green-mid;}
      h2, h6 {color: $green;}
    }
    &.is-gray {
      h5 {color: $gray-mid;}
      h2, h6 {color: $gray;}
    }
    &.is-blue {
      h5 {color: $blue-mid;}
      h2, h6 {color: $blue;}
    }
  }
  .svg-parent {
    position: relative;
    display: flex;
    width: 30rem;
    align-self: center;
    background: $cherry-light;
    border-radius: 5px;
    margin-bottom: 1rem;
    height: 10rem;
    svg {
      .domain, .tick, line {
        stroke: $cherry;
        stroke-width: 2;
      }
      text {  
        font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        text-transform: uppercase;
        color: $cherry-mid;
        stroke: transparent;
        font-size: 12px;
      }
    }
    &.is-green {
      background: $green-light;
      svg{
        .domain, .tick, line {
          stroke: $green;
        }
        text {
          color: $green-mid;
          stroke: transparent;
        }
      }
    }
    &.is-gray {
      background: $gray-light;
      .domain, .tick, line {
        stroke: $gray;
      }
      text {
        color: $gray-mid;
        stroke: transparent;
      }
    }
  }
}

.Minigraph {
  width: 30rem;
  align-self: center;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .svg-parent {
    width: 5rem;
    background: transparent !important;
    height: 7rem;
  }
  margin-bottom: -3rem;
  .tooltip {
    position: fixed;
    top: 0;
    right: 0;
  }
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HelpLine {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Tweets {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link {
  width: 30rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;
  a {
    font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    text-transform: none;
    word-wrap: break-word;
    font-weight: 500;
    text-decoration: none;
    color: $blue;
    background: $blue-light;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: $blue-hover;
    }
  }
  h3 {
    margin-bottom: 0;
    color: $gray-dark;
    font-weight: 600;
  }
}

.link2 {
  width: 30rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;
  a {
    font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    text-transform: none;
    word-wrap: break-word;
    font-weight: 500;
    text-decoration: none;
    color: $blue;
    background: $blue-light;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: $blue-hover;
    }
  }
  h3 {
    margin-bottom: 0;
    color: $gray-dark;
    font-weight: 600;
  }
}

.link3 {
  width: 30rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;
  a {
    font-family: 'Avenir',-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    text-transform: none;
    word-wrap: break-word;
    font-weight: 500;
    text-decoration: none;
    color: $blue;
    background: $blue-light;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: $blue-hover;
    }
  }
  h3 {
    margin-bottom: 0;
    color: $gray-dark;
    font-weight: 600;
  }
}

footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  justify-content: center;
  margin-top: 5rem;
  & > * {
    align-self: center;
    text-align: center;
  }
  h5 {margin: 0; margin-top: 1rem; margin-bottom: 0.5rem; color: $gray;}
  img {
    width: 2rem;
    height: 2rem;
  }
}

.Summary {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 600px;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  img {
    width: 2rem;
    height: 2rem;
  }
  .link {
    margin: 0;
  }
  .link2 {
    margin: 0;
  }
  .link3 {
    margin: 0;
  }
  .header {
    h1 {
      margin-top: -1rem;
    }
  }
  h5 {
    margin: 0;
    color: $gray;
  }
  .Minigraph {
    align-self: center;
    margin-bottom: -3.25rem
  }
  .summary-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: calc(100% - 0rem);
    .link {
      width: 12rem;
      margin-right: -0.75rem;
    }
    .link2 {
      width: 12rem;
      margin-right: -0.75rem;
    }
    .link3 {
      width: 12rem;
      margin-right: -0.75rem;
    }
    & > * {
      align-self: center;
    }
    img {
      margin-bottom: 0.25rem;
      margin-right: 0.25rem;
      margin-left: 1rem;
    }
    h5 {
      width: 15rem;
    }
  }
  .summary-bottom-left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    & > * {
      align-self: center;
    }
  }
}

.FAQ {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30rem;
  & > * {
    align-self: center;
    width: 30rem;
    margin-bottom: 1.5rem;
  }
  & > *:first-child {
    margin-top: 2rem;
  }
  h2 {
    margin: 0;
    font-weight: 600;
    text-transform: none;
  }
  .question {
    margin-bottom: 0.5rem;
    color: $gray-dark;
  }
  .answer {
    color: $blue;
  }
}

@media (max-width: 769px) {
  .ChoroplethMap, .TimeSeries, table, .header, .Level, .timeseries-header, .TimeSeries-Parent, .Links, .HelpLine, .Tweets, .Minigraph, .Summary, .FAQ {
    width: calc(100% - 2rem);
  }
  .Home {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
    .home-left, .home-right {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .link, .faq, .link2, .link3 {
    width: calc(100% - 2rem);
  }
  .Navbar {
    a {
      font-size: 0.75rem !important;
      margin-right: 0.75rem !important;
    }
    img {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
      margin-right: -1rem;
      cursor: pointer;
    }
  }
  .timeseries {
    .svg-parent {
      width: 100%;
    }
  }
  .last-update {
    width: 10rem;
  }
  .Banner {
    height: 4rem;
    .snippet {
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  a.button {
    width: 10rem;
    svg {
      width: 38px;
    }
  }
}


/* Animation Support */

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.450s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.iframe-container{
  width: 100%;
  min-height : 100vh;
  iframe {
    width: 100vw;
    height: 100vh;
    border: none;
  }
}
